<template>
  <div class="areaList">
    <div class="areaList-box">
      <el-checkbox-group v-model="checkList" class="grid">
        <div v-for="item in businessDistrictList" :key="item.district_id">
          <el-checkbox
            :label="item.district_id"
            :disabled="
              $route.query.type == 1 || item.business_id ? true : false
            "
          >
            {{ item.name }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
    <div class="operate-btn" v-if="!$route.query.type == 1">
      <div
        class="go-back"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
      <div class="save" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const commonIndex = createNamespacedHelpers("commonIndex");
const AuditIndex = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      props: {
        label: "name",
        children: "children",
      },
      checkList: [],
    };
  },

  computed: {
    ...commonIndex.mapState(["AreaList"]),
    ...merchantCenterList.mapState(["businessDistrictList", "selectNode"]),
  },
  mounted() {
    this.getchaeckList();
  },
  methods: {
    ...merchantCenterList.mapActions(["getDistrictList"]),
    ...AuditIndex.mapActions(["postreviewsetDistrict"]),
    getchaeckList() {
      //console.log(this.businessDistrictList, "qweqw");
      this.businessDistrictList.forEach((item) => {
        if (item.business_id != null) {
          this.checkList.push(item.district_id);
        }
      });
      //console.log(this.checkList);
    },
    // 保存地区
    async save() {
      // 保存地区选择
      let data = await this.postreviewsetDistrict({
        business_id: this.$route.query.id,
        district_ids: this.checkList,
      });
      if (data.code == 200) {
        // 获取可经营地区列表
        this.getDistrictList({ business_id: this.$route.query.id });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.areaList {
  // height: 600px;
  position: relative;
  height: 100%;
  // overflow: auto;
  .areaList-box{
    height: 100%;
  }
}
.operate-btn {
  bottom: 30px;
  height: 80px;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 99;
  .save {
    text-align: center;
    line-height: 32px;
    margin-left: 10px;
    width: 88px;
    height: 32px;
    background: #00bfbf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(6, 18%);
  // padding: 20px 0;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  div {
    padding: 10px 0;
  }
}
</style>
