<template>
  <div class="baitiao">
    <div class="business-1-box scorr-roll">
      <!-- <div class="btTips">免息时长，不可更改，如需更改联系商务人员</div> -->
      <!-- {{ businessInfo }} -->
      <div class="box-span">
        <span>免息时长:</span>
        <span>{{ businessInfo.loanMonth }}个月</span>
        <span style="margin-left: 50px"
          >最低分佣比例：{{ businessInfo.loanMonthCommission }}%</span
        >
        <el-button
          type="primary"
          size="mini"
          @click="toXg"
          v-if="!$route.query.type == 1"
          >修改</el-button
        >
      </div>
      <div class="universal-title">白条分佣设置</div>
      <el-form
        v-if="!$route.query.type == 1"
        ref="Whitesitem"
        :model="Whitesitem"
        label-width="45%"
        :rules="rules"
      >
        <el-form-item label="白条佣金出资方：" prop="investor">
          <el-select
            clearable
            v-model="Whitesitem.investor"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in investorList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="平台出资比例："
          v-if="Whitesitem.investor == 2"
          prop="platformProvidePercent"
        >
          <el-input
            maxlength="32"
            v-model="Whitesitem.platformProvidePercent"
            placeholder="请输入平台出资比例"
            @input="
              Whitesitem.platformProvidePercent =
                Whitesitem.platformProvidePercent.replace(/^(0+)|[^\d]+/g, '')
            "
            clearable
            style="width: 200px"
          />
          <span>&emsp;%</span>
        </el-form-item>
        <el-form-item label="白条佣金基数对象：" prop="commisionFrom">
          <el-radio-group v-model="Whitesitem.commisionFrom">
            <el-radio :label="0">订单金额</el-radio>
            <el-radio :label="1">实付金额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="白条分佣比例：" prop="commissionPercent">
          <el-input
            maxlength="32"
            v-model="Whitesitem.commissionPercent"
            @input="
              Whitesitem.commissionPercent =
                Whitesitem.commissionPercent.replace(/^(0+)|[^\d]+/g, '')
            "
            placeholder="请输入分佣比例"
            clearable
            style="width: 200px"
          />
          <span>&emsp;%</span>
        </el-form-item>
        <el-form-item label="白条分佣解绑条件：" prop="unboundType">
          <el-select
            clearable
            v-model="Whitesitem.unboundType"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in conditionList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="白条分佣解绑时效："
          v-if="Whitesitem.unboundType != 3"
          prop="bindingDays"
        >
          <el-input
            maxlength="32"
            v-model="Whitesitem.bindingDays"
            placeholder="请输入分佣解绑时效"
            @input="
              Whitesitem.bindingDays = Whitesitem.bindingDays.replace(
                /^(0+)|[^\d]+/g,
                ''
              )
            "
            clearable
            style="width: 200px"
          />
          <span>&emsp;天</span>
        </el-form-item>
        <el-form-item label="跟随平台设置变动：" prop="standardFrom">
          <el-switch
            v-model="Whitesitem.standardFrom"
            active-color="#00bfbf"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div v-else style="line-height: 25px" class="baitiao-box">
        <div>白条佣金出资方：{{ Whitesitem.investor | investor }}</div>
        <div v-if="Whitesitem.investor == 2">
          平台出资比例：{{ Whitesitem.platformProvidePercent }}%
        </div>
        <div>
          白条佣金基数对象：{{
            Whitesitem.commisionFrom == 0 ? "订单金额" : "实付金额"
          }}
        </div>
        <div>白条分佣比例：{{ Whitesitem.commissionPercent }}%</div>
        <div>白条分佣解绑条件：{{ Whitesitem.unboundType | unboundType }}</div>
        <div v-if="Whitesitem.unboundType != 3">
          白条分佣解绑时效：{{ Whitesitem.bindingDays }}天
        </div>
        <div>
          跟随平台设置变动：{{ Whitesitem.standardFrom == 1 ? "开启" : "关闭" }}
        </div>
      </div>
      <div>
        <el-dialog
          title="修改白条免息期"
          :visible.sync="isTk"
          :close-on-click-modal="false"
          width="450px"
        >
          <div>
            <div class="tlty">修改后将影响供应商的在售商品，请谨慎操作</div>
            <el-form ref="form" label-width="100px">
              <el-form-item label="免息时长">
                <el-select
                  style="width: 250px"
                  v-model="bTvAL"
                  placeholder="请选择"
                  @change="cghangesel(bTvAL)"
                >
                  <el-option
                    v-for="item in PzList"
                    :key="item.id"
                    :label="item.month"
                    :value="item.month"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="最低分佣比例">
                <el-input
                  @input="newText = newText.replace(/^(0+)|[^\d]+/g, '')"
                  style="width: 230px"
                  v-model="newText"
                ></el-input
                ><span>&nbsp;%</span>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isTk = false" style="margin-right: 10px"
              >取 消</el-button
            >
            <el-button type="primary" @click="xGbusinessEdit">修 改</el-button>
          </span>
          <div class="atsedf" v-if="atesd1">请先选择免息时长</div>
          <div class="atsedf" v-if="atesd2">最低分佣比例不能为空</div>
          <div class="atsedf" v-if="atesd3">请输入100以内正整数</div>
          <div class="atsedf" v-if="atesd4">
            最低分佣比例不能小于{{ minnum }}%
          </div>
          <div class="bubble-box" v-if="vesicle">
            <div class="bubble">
              <i class="el-icon-warning"></i>
              <span>修改后将影响供应商的在售商品，请谨慎操作。</span>
              <div class="buste">
                <el-button class="cancel" size="mini" @click="vesicle = false"
                  >取消</el-button
                >
                <el-button
                  class="affirmclose"
                  size="mini"
                  @click="xGbusinessEditApi"
                  >修改</el-button
                >
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="operate-btn" v-if="!$route.query.type == 1">
      <div
        class="go-back"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
      <div class="save" @click="changsliitem">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const BasicsIndex = createNamespacedHelpers("Basics");
export default {
  data() {
    return {
      atesd1: false,
      atesd2: false,
      atesd3: false,
      atesd4: false,
      vesicle: false,
      minnum: "",
      isTk: false,
      PzList: [],
      bTvAL: "",
      newText: "",
      Whitesitem: {}, //白条分佣设置
      investorList: [
        {
          name: "平台出资",
          id: 0,
        },
        {
          name: "供应商出资",
          id: 1,
        },
        {
          name: "双方出资",
          id: 2,
        },
      ], //白条佣金出资方
      conditionList: [
        {
          name: "时效内未使用白条",
          id: 1,
        },
        {
          name: "时效内未发生交易",
          id: 2,
        },
        {
          name: "不解绑",
          id: 3,
        },
      ], //白条分佣解绑条件
      rules: {
        investor: [
          { required: true, message: "请选择白条佣金出资方", trigger: "blur" },
        ],
        platformProvidePercent: [
          { required: true, message: "请选择平台出资比例", trigger: "blur" },
        ],
        commisionFrom: [
          {
            required: true,
            message: "请选择白条佣金基数对象",
            trigger: "blur",
          },
        ],
        commissionPercent: [
          { required: true, message: "请输入白条分佣比例", trigger: "blur" },
        ],
        unboundType: [
          {
            required: true,
            message: "请选择白条分佣解绑条件",
            trigger: "blur",
          },
        ],
        bindingDays: [
          { required: true, message: "请输入分佣解绑时效", trigger: "blur" },
        ],
        standardFrom: [
          {
            required: true,
            message: "请选择是否跟随平台设置变动",
            trigger: "blur",
          },
        ],
      },
    };
  },
  filters: {
    investor(v) {
      switch (v) {
        case 0:
          return "平台出资";
        case 1:
          return "供应商出资";
        case 2:
          return "双方出资";
      }
    },
    unboundType(v) {
      switch (v) {
        case 1:
          return "时效内未使用白条";
        case 2:
          return "时效内未发生交易";
        case 3:
          return "不解绑";
      }
    },
  },
  computed: {
    ...merchantCenterList.mapState(["businessInfo"]),
  },
  async created() {
    let data = await this.getselectIOU({
      businessId: this.$route.query.id,
    });
    this.$nextTick((_) => {
      this.Whitesitem = { ...data.data };
    });
  },
  methods: {
    ...merchantCenterList.mapActions([
      "businessEdit",
      "getBusinessInfo",
      "reviewSetLoan",
      "getselectIOU",
      "updateIOU",
      "getCredentials", //审核记录详情（资质详情）
    ]),
    ...BasicsIndex.mapActions(["getloanCommissionlist"]),
    xGbusinessEdit() {
      this.atesd1 = false;
      this.atesd2 = false;
      this.atesd3 = false;
      this.atesd4 = false;
      if (this.bTvAL == "") return (this.atesd1 = true);
      if (this.newText == "") return (this.atesd2 = true);
      if (this.newText > 100 || this.newText == 100)
        return (this.atesd3 = true);
      this.newText = this.newText * 1;
      let minnum = this.PzList.filter((v) => {
        return v.month == this.bTvAL;
      });
      if (this.newText < minnum[0].commission) {
        this.minnum = minnum[0].commission;
        return (this.atesd4 = true);
      }
      this.vesicle = true;
    },
    async xGbusinessEditApi() {
      let api = {
        business_id: this.$route.query.id,
        loan_month: this.bTvAL,
        loan_month_commission: this.newText,
      };
      let data = await this.reviewSetLoan(api);
      if (data.code == 200) {
        this.vesicle = false;
        // 获取供应商信息
        await this.getCredentials({
          businessId: this.$route.query.id,
          auditSerialNumber: this.$route.query.auditSerialNumber,
        });
        this.isTk = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 打开弹框
    toXg() {
      this.$confirm(
        "确定要修改白条免息期信息，修改后将会影响所有商品（自营商品、共享的商品及分销其他供应商的商品）销售情况，请谨慎修改。",
        "修改确认",
        {
          confirmButtonText: "立即修改",
          cancelButtonText: "取消",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.getloanCommissionlist().then((data) => {
            if (data.code == 200) {
              this.PzList = data.data;
              this.PzList.forEach((v, i) => {
                v.monthName = v.month;
                if (v.month == this.businessInfo.loanMonth) {
                  this.bTvAL = v.month;
                }
              });
              this.newText = this.businessInfo.loanMonthCommission;
              this.isTk = true;
              this.atesd1 = false;
              this.atesd2 = false;
              this.atesd3 = false;
              this.atesd4 = false;
            } else {
              this.$message({
                message: data.message,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },
    // 切换
    cghangesel(val) {
      this.PzList.forEach((v, i) => {
        if (v.month == val) {
          if (v.commission >= this.newText) {
            this.newText = v.commission;
          }
        }
      });
      // val.monthName=val.month
      // let data = this.PzList.filter((item) => {
      //   return item.month == val;
      // });
    },
    //修改白条分佣
    async changsliitem() {
      this.$refs["Whitesitem"].validate((valid) => {
        if (valid) {
          if (
            this.Whitesitem.investor == 2 &&
            this.Whitesitem.platformProvidePercent > 100
          )
            return this.$message.error("出资比例不能大于100");
          if (
            this.Whitesitem.investor != 2 &&
            this.Whitesitem.commissionPercent > 100
          )
            return this.$message.error("白条分佣比例不能大于100");
          this.updateIOU(this.Whitesitem).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            this.$router.push({ path: "/merchantCenterList" });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.baitiao {
  // padding: 20px;
  font-size: 14px;
  position: relative;
}
.operate-btn {
  bottom: 30px;
  height: 80px;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 99;
  .save {
    text-align: center;
    line-height: 32px;
    margin-left: 10px;
    width: 88px;
    height: 32px;
    background: #00bfbf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
}
.box-span {
  // margin-top: 30px;
  width: 100%;
  background-color: rgba($color: #16e1d7, $alpha: 0.1);
  padding: 12px 40px;
  border-radius: 8px;
  .el-button {
    margin-left: 10px;
  }
}
.baite {
  font-weight: bold;
  font-size: 15px;
  margin: 20px 0px;
}
.tlty {
  margin: 20px 0px;
}
.atsedf {
  border: 1px solid rgba(255, 191, 163, 1);
  border-radius: 4px;
  background: rgba(255, 239, 230, 1);
  padding: 10px;
}
.universal-title {
  margin: 20px 0 40px;
}
.business-1-box {
  height: 64vh;
}
.baitiao-box {
  margin-left: 42%;
}
</style>
